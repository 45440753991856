<template>
  <div class="ui stackable container grid row dataroom">
    <nav class="four wide column col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <BackBtn />
      <h2 class="navigation-title h2">Data Room</h2>
      <ul class="list-group">
        <li v-for="(step, stepIndex) in steps" :key="stepIndex">
          <router-link class="list-group-item-navigator" :to="{ params: { step: step.name } }" replace v-if="!step.hidden">
            <span class="text">{{ step.navTitle }}</span>
            <span class="progress" v-if="filesList">{{ sortedFilesLists[step.type] ? sortedFilesLists[step.type].length : 0 }}</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <section class="twelve wide column col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div class="row ui flex center">
        <header class="col-xs-12 col-md-12 col-lg-12 text-left form-title">
          <h3 class="ui h1 text f400">{{ currentForm.navTitle }}</h3>
        </header>
        <div class="ui active inverted dimmer page-loader" v-if="isLoading">
          <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
        </div>
        <div class="document-form" v-else>
          <div class="document-form-text text-left">
            <div class="document-form-label">{{ currentForm.formTitle }}</div>
            <span class="document-form-sublabel">To upload, make sure it’s a PDF smaller than 20MB</span>
          </div>
          <SurveyDocuments
            :documentCategory="currentForm.type"
            v-model="currentForm.groups[0].fields[0][0].selected"
            :rules="currentForm.groups[0].fields[0][0].rules"
            :formName="currentForm.groups[0].fields[0][0].name"
            :name="currentForm.groups[0].fields[0][0].name"
            :multiple="currentForm.groups[0].fields[0][0].choice === 'multiple'"
            :label="currentForm.groups[0].fields[0][0].label"
            :submit-promise="submitPromise"
            :filesList="sortedFilesLists"
            @input="requestFilesList"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import deepcopy from 'deepcopy'
import steps from './dataRoom.steps'
import BackBtn from '@/components/BackBtn'
import Spinner from '@/components/Spinner'
import SurveyDocuments from '@/components/forms/SurveyDocuments'
import { getDocumentsList } from '@/api/document'
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'

export default {
  name: 'DataRoom',
  components: {
    BackBtn,
    SurveyDocuments,
    Spinner
  },
  data() {
    return {
      isLoading: true,
      steps: deepcopy(steps),
      submitPromise: null,
      filesList: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    currentStepName() {
      return this.$route.params.step || steps[0].name
    },
    currentForm() {
      return this.steps.find(({ name }) => name === this.currentStepName)
    },
    sortedFilesLists() {
      const filesLists = {}
      if (this.filesList && this.filesList.length) {
        this.filesList.forEach(file => {
          // Pitch Deck exclusion START
          if (file.type === 'FundingDocuments' && file.title === 'Pitch Deck') {
            const type = 'PitchDeck'
            if (filesLists[type]) {
              filesLists[type].push(file)
            } else {
              filesLists[type] = [file]
            }
          }
          // Pitch Deck exclusion END
          if (filesLists[file.type]) {
            filesLists[file.type].push(file)
          } else {
            filesLists[file.type] = [file]
          }
        })
      }
      return filesLists
    }
  },
  methods: {
    async requestFilesList() {
      this.filesList = null
      this.isLoading = true
      try {
        const response = await getDocumentsList(this.companyId)
        this.filesList = response.data
        this.isLoading = false
      } catch (e) {
        this.filesList = []
      }
    },
    getNextUnhiddenStepPath() {
      const unhiddenStep = this.steps.find(({ hidden }) => !hidden)
      if (!unhiddenStep) return '/'
      return `/data-room/${unhiddenStep.name}`
    }
  },
  async beforeMount() {
    if (this.currentForm?.hidden) {
      this.$router.push({ path: this.getNextUnhiddenStepPath() })
    }
    this.requestFilesList()
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';
.ui.dataroom {
  .btn-back {
    margin: 2rem 0 1rem;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
    &.list-group-item-navigator {
      margin-bottom: 8px;
      @media only screen and (min-width: $breakpoint-sm) {
        width: 292px;
      }
    }
  }
  .form-title {
    margin: 1rem 0;
    font-size: 20px;
    padding: 2rem;
    margin-bottom: 0rem;
    @media only screen and (max-width: $breakpoint-sm) {
      margin: 0rem;
    }
    .h1 {
      font-size: 1.75rem;
    }
  }

  .document-form {
    display: inline-block;
    padding: 3rem 2rem;
    border-radius: 12px;
    box-shadow: $default-box-shadow;
    min-height: 564px;
    width: 100%;
    @media only screen and (max-width: $breakpoint-sm) {
      padding: 1rem;
    }
    .document-form-text {
      margin-bottom: 17px;
    }
    .document-form-label {
      font-size: 20px;
      line-height: 28px;
      color: var(--color-primary-500);
      margin-bottom: 20px;
    }
    .document-form-sublabel {
      font-size: 16px;
      line-height: 24px;
    }
  }
  > .row {
    max-width: 100%;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .ui.dataroom {
    padding: 10px 10px;
  }
}
</style>
