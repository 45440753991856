import { whiteListDocumentExtensions } from '@/utils/constants'
import config from '@/config/global'

export default [
  {
    name: 'financials',
    type: 'Financials',
    formTitle: 'Manage your financials',
    navTitle: 'Financials',
    completed: null,
    maxGroupColumns: 12,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'ownership',
    type: 'Ownership',
    formTitle: 'Manage your ownership documents',
    navTitle: 'Ownership',
    completed: null,
    maxGroupColumns: 12,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'applications',
    type: 'Applications',
    formTitle: 'Manage your Applications',
    navTitle: 'Applications',
    completed: null,
    maxGroupColumns: 12,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'fundingDocuments',
    type: 'FundingDocuments',
    formTitle: 'Manage your funding documents',
    navTitle: 'Funding documents',
    completed: null,
    maxGroupColumns: 12,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'pitchDeck',
    type: 'PitchDeck',
    formTitle: 'Manage your Pitch Deck',
    navTitle: 'Pitch Deck',
    completed: null,
    maxGroupColumns: 12,
    hidden: !config.whitelabel.components.pitchDeckEnabled,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'identification',
    type: 'Identification',
    formTitle: 'Manage your identification documents',
    navTitle: 'Identification',
    completed: null,
    maxGroupColumns: 12,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  },
  {
    name: 'other',
    type: 'Other',
    formTitle: 'Other documents',
    navTitle: 'Other',
    completed: null,
    maxGroupColumns: 12,
    groups: [
      {
        title: '',
        fields: [
          [
            {
              choice: 'multiple',
              field: 'survey-documents',
              type: '',
              name: 'files',
              veeAs: 'files',
              label: 'Upload your deck (ppt, pdf)',
              placeholder: '',
              rules: `size:20480|ext:${whiteListDocumentExtensions}`,
              selected: ''
            }
          ]
        ]
      }
    ]
  }
]
